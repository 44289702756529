import Pathfind from "../../../util/pathfind";
import type Game from "../../game";
import IGameMode, { CombatMode, CombatStats, IPathFindResult } from "./igamemode";
import MAP_SHAPES from "../../../../components/game/map/shapes";
import { BonusCalculation } from "../action";
import { ApiCountry, ApiSeat } from "../../../types/apigame";
import { GameDangerousAttackType } from "../../../enum/game";

export default class GameModeStandard implements IGameMode {
    constructor(protected gameController: Game) { }

    public areAllies(seat1: number, seat2: number): boolean {
        return seat1 === seat2;
    }

    public canDeploy(
        seatNumber: number,
        countryId: number,
    ): boolean {
        const seatObject = this.gameController.getSeatObjectBySeatNumber(seatNumber);

        if (!seatObject) {
            return false;
        }

        const country = this.gameController.getCountryById(countryId);
        if (!country || country.seatNumber < 0) {
            return false;
        }

        return country.seatNumber === seatNumber;
    }

    public canFortifyFrom(seatNumber: number, fromCountryId: number): boolean {
        const fromCountry = this.gameController.getCountryById(fromCountryId);
        if (!fromCountry) {
            return false;
        }

        if (fromCountry.seatNumber < 0) {
            return false;
        }

        return fromCountry.seatNumber === seatNumber;
    }

    public canFortify(
        seatNumber: number,
        fromCountryId: number,
        toCountryId: number,
    ): boolean {
        const fromCountry = this.gameController.getCountryById(fromCountryId);
        const toCountry = this.gameController.getCountryById(toCountryId);

        if (!fromCountry || !toCountry) {
            return false;
        }

        if (fromCountry.seatNumber < 0 || toCountry.seatNumber < 0) {
            return false;
        }

        if (fromCountry.seatNumber !== toCountry.seatNumber) {
            return false;
        }

        if (fromCountry.seatNumber !== seatNumber) {
            return false;
        }

        if (!this.gameController.hasBorder(fromCountryId, toCountryId)) {
            return false;
        }

        return true;
    }

    public fortifyPathFind(
        fromCountry: ApiCountry,
        fortifyCount: number,
    ): IPathFindResult {
        const rules = this.gameController.getRules();

        let maxDistance = Number.MAX_SAFE_INTEGER;
        if (rules.maxFortifies > 0) {
            maxDistance = rules.maxFortifies - fortifyCount;
        }

        const pathfind = new Pathfind(
            this.getFortifiableCountries(fromCountry.seatNumber)
                .map((country) => country.countryId),
            this.gameController.game.map.data.borders,
        );

        const paths = pathfind.shortestPath(
            fromCountry.countryId,
            maxDistance,
            this.getFortifyPathFindTerminalNodes(fromCountry.seatNumber),
        );

        const countries = paths.map(
            (path) => this.gameController.getCountryById(path[path.length - 1]),
        );

        const pathMap = {};
        paths.forEach(
            (path) => pathMap[path[path.length - 1]] = path,
        );

        return ({
            countries,
            paths: pathMap,
        });
    }

    public getCombatStats(countryId: number, mode: CombatMode): CombatStats {
        const rules = this.gameController.getRules();
        const mapCountry = this.gameController.getMapCountry(countryId);

        return {
            dieFaces: mode === CombatMode.Attack
                ? Number(rules.defaultAttackDieFaces) || 0
                : Number(rules.defaultDefendDieFaces) || 0,
            bonus: mode === CombatMode.Attack
                ? Number(mapCountry.attackBonus) || 0
                : Number(mapCountry.defendBonus) || 0,
            maxUnits: mode === CombatMode.Attack
                ? 3
                : 2,
        };
    }

    public getFortifiableCountries(seatNumber: number): ApiCountry[] {
        return this.gameController.game.countries
            .filter((country) => country.seatNumber === seatNumber)
            .filter((country) => {
                const mapCountry = this.gameController.getMapCountry(country.countryId);
                if (mapCountry.maxUnits > 0 && country.units == mapCountry.maxUnits) {
                    return false;
                }
                return true;
            });
    }

    protected getFortifyPathFindTerminalNodes(seatNumber: number): number[] {
        return [];
    }

    public getGameModeName(): string {
        return 'World Domination';
    }

    public getGameModeDescription(): string {
        return 'Eliminate all players and take over the world!';
    }

    public getGameModeIconName(): string {
        return 'standard';
    }

    public getLobbyIcon(seatObject: any): any {
        return null;
    }

    public getShapeIndex(seatNumber: number): number {
        const numShapes = MAP_SHAPES.length;
        return (seatNumber + 1) % numShapes;
    }

    public getNameForSeat(seatNumber: number): string {
        const seatObject = this.gameController.getSeatObjectBySeatNumber(seatNumber);
        return seatObject.name;
    }

    public getPlayerListIconForSeat(seatNumber: number, localPlayerSeatNumber: number | undefined): any {
        return null;
    }

    public getPlayerListTagIconForSeat(seatNumber: number) {
        return null;
    }

    public getBonusCalculationOnTurnStart(seatNumber?: number): BonusCalculation {
        if (!(seatNumber >= 0)) {
            seatNumber = this.gameController.game.turn;
        }

        return {
            countryBonus: this.gameController.getCountryBonus(seatNumber),
            continentBonus: this.gameController.getContinentBonus(seatNumber),
            cardSetBonus: 0,
            eliminationBonus: 0,
            capitalBonus: 0,
        };
    }

    public getWinner(): string {
        const seatObject = this.gameController.getSeatObjectBySeatNumber(
            this.gameController.game.turn,
        );

        return seatObject.name;
    }

    public getWinnerSeats(): ApiSeat[] {
        return [this.gameController.getSeatObjectBySeatNumber(
            this.gameController.game.turn,
        )];
    }

    isCountryAdjacentToSeat(countryId: number, seatNumber: number): boolean {
        return this.gameController.game.countries
            .some(
                (country) => this.areAllies(country.seatNumber, seatNumber)
                    && this.gameController.hasBorder(country.countryId, countryId),
            );
    }

    public isDangerousAttack(
        fromCountry: ApiCountry,
        toCountry: ApiCountry,
    ): GameDangerousAttackType {
        return GameDangerousAttackType.None;
    }

    public isGameOver(countryToExclude?: ApiCountry): boolean {
        const seatsPlaying = {};

        this.gameController.game.countries.forEach(
            country => {
                const seatObject = this.gameController.getSeatObjectBySeatNumber(
                    country.seatNumber,
                );

                if (
                    country.seatNumber >= 0
                    && seatObject.accepted
                    && (
                        !countryToExclude
                        || countryToExclude.countryId !== country.countryId
                    )
                ) {
                    seatsPlaying[country.seatNumber] = true;
                }
            },
        );

        return Object.keys(seatsPlaying).length <= 1;
    }
}
